.demo-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
  }
  
  .theme-card {
    width: 250px;
    border-radius: 12px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .theme-image {
    position: relative;
    width: 100%;
    height: 150px;
    background: #ddd;
  }
  
  .theme-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .theme-name-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 18px;
    font-weight: bold;
    border-radius: 6px;
  }
  
  .view-demo-button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-top: 1px solid #ddd;
    background: rgb(83, 83, 83,0.8);
    transition: background 0.3s;
  }
  
  .view-demo-button:hover {
    background: blue;
    color: white;
  }
  
  .view-demo-icon{
    font-size: 20px !important;
    transform: translateY(-1px);
    margin-left: 5px;
  }

  .explore-theme-heading{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 15px 10px;
  }

 .white-text{
    color: white;
 }

  @media(min-width:374px){
    .explore-theme-heading{
        font-size: 22px;
        margin: 15px 30px;
    } 
    .theme-card {
      width: 270px;
    }
  }

  @media(min-width:424px){
    .explore-theme-heading{
        font-size: 24px;
        margin: 15px 30px;
    } 
  }

  @media(min-width:767px){
    .explore-theme-heading{
        font-size: 24px;
        margin-top: 25px;
    } 
  }

