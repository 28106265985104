.subheading{
    text-transform: uppercase;
    font-size: 22px;
}
.update-email-box{
    position: relative;  
    border: 2px solid grey;
    border-radius: 5px;
    margin:  10px 0;
}

.error-update-email-box{
    position: relative;  
    border: 2px solid red;
    border-radius: 5px;
    margin:  10px 0;
}

.error-update-email-box .update-label-icon{
    color: red;
   
}

.error-update-email-box .update-email-label{
    color: red;
   
}

.error-update-email-box .error-update-label-icon{
 display: block;
   
}
.update-input-feild{
    border: none;
    background: white;
    outline: none;
   border-radius: 5px;
   width: 80%;
   padding: 10px;
   margin: 8px 30px;
   position: relative;
}
.update-email-label{
    position: absolute;
    top:15px ;
    left: 50px;
    transition:.3s ;
    font-size: 20px;
}

.update-input-feild:focus ~ .update-email-label,
.update-input-feild:not(:placeholder-shown).update-input-feild:not(:focus) ~ .update-email-label
{
top:-13px;
left:40px;
font-size: 16px;
background:white ;
}

.update-label-icon{
    position: absolute;
    top:18px;
    padding: 0 5px;
    left: 0;
}
.update-icon{
    font-size: 28px !important;
}
.error-update-label-icon{
    color: red;
    position: absolute;
    right: 10px;
    top: 15px;
    display: none;
}
.update-button{
    width: 80%;
    display: block;
    outline: 0;
    border: 0;
    margin: auto;
    margin-top: 8px;
    font-weight: bolder;
    border-radius: 20px;
    box-shadow: 0px 5px 7px 0px rgba(63, 45, 45, 0.60);
    padding: 10px  5px;
    color: white;
    background-color: blue;
}

.error-container{
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 231, 231);
    position: fixed;
    top: 0%;
    left: 0;
}
.error-box{
    background-color: white;
    width: 40%;
    height: 500px;
    box-sizing: border-box;
    overflow: hidden;
}
.error-box > img{
    width: 90%;
    height: 300px;
    object-fit: contain;
    margin: auto;
    display: block;
}
.error-box > h3{
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
}
.error-box > button{
    width: 80%;
    margin: 20px auto;
   display: block;
   border: 0;
   padding: 10px 0;
   border-radius: 10px;
    font-size: 14px;
    background: #6C63FF;
    color: white;
    font-weight: bold;
}

@media(max-width:768px){
   .error-box{
    width: 60%;
    height: 400px;
    }
    .error-box > h3{
        font-size: 16px;
     }
}

@media(max-width:425px){
    .error-box{
     width: 60%;
     height: 420px;
     }
     .error-box  > img{
        height: 250px;
     }
    
 }

 @media(max-width:375px){
    .error-box{
     width: 80%;
     height: 420px;
     }
    
 }
