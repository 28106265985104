
.preview-text{
    position: fixed;
    top: 10px;
    left: 10px;
    padding: 5px;
    border-radius: 5px;
    text-transform: capitalize;
    background-color: black;
    color: white;
    z-index: 2000;
}

.visible-icon{
    margin-left: 5px;
    margin-bottom: 3px;
    margin-right: 3px;
}

.use-button{
    position: fixed;
    bottom: 15px;
    z-index: 2000;
    width: 90%;
   left: 15px;
   padding: 8px 5px;
   border: 0;
   border-radius: 5px;
   background-color: grey;
   color: white;
   font-weight: bolder;
   text-transform: capitalize;
}



.arrow-use{
    margin-left: 8px;
    transform: translateY(-1px);   
}

.cancel-use{
    position: fixed;
    z-index: 2000;
    right: 15px;
    top: 8px;
    width: 35px !important;
    height: 35px !important;
}

.navlink-use{
    color: black;
}
.navlink-use:hover{
    color: red;
}

@media(min-width:375px){
    .preview-text{
        top: 15px;
        left: 15px;
        padding: 8px;
       font-size: 16px;
    }
    
    .cancel-use{
        top: 8px;
        width: 40px !important;
        height: 40px !important;
    }

    .use-button{
        width: 85%;
       left: 25px;
       padding: 10px 5px;
       font-size: 20px;
    }
}

@media(min-width:424px){
    .preview-text{
       font-size: 18px;
    }
    
    .cancel-use{
        width: 40px !important;
        height: 40px !important;
    }

    .use-button{
        width: 80%;
       left: 45px;
       font-size: 18px;
    }
}

@media(min-width:767px){
    .preview-text{
        top: 20px;
        left: 20px;
       font-size: 20px;
    }
    
    .cancel-use{
        top: 10px;
        right: 35px;
        width: 45px !important;
        height: 45px !important;
    }

    .use-button{
        width: 70%;
       left: 120px;
       padding: 10px 5px;
       font-size: 20px;
    }
}

@media(min-width:1000px){
    .preview-text{
        top: 30px;
        left: 30px;
       font-size: 22px;
    }
    
    .cancel-use{
        top: 10px;
        right: 45px;
        width: 50px !important;
        height: 50px !important;
    }

    .use-button{
        width: 55%;
       left: 300px;
       padding: 10px 5px;
       font-size: 22px;
    }

    .visible-icon{
        width:30px !important;
        height: 30px !important;
        margin-left: 10px;
    }
}