.default-bg-conatiner{
    background-color: rgba(0, 0,0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  z-index: 1;
}
.cancel-icon{
  font-size: 35px !important;
  position: absolute;
  top: 10px;
  right: 40px;
}
.default-bg-screen{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-background-container >h3{
  margin-top: 15px;
}
.select-background-container{
  width: 60%;
  background-color: white;
  border-radius: 10px;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 450px;
  overflow: scroll;

}
.background-container{
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 22px;
  justify-items: center;
}
.cancel-icon:hover{
   color: red;
}
.active-theme {
  border: 5px solid gold;
  border-radius: 10px;
}
.box{
   width: 150px;
   height: 150px;
   border-radius: 5px;
   cursor: pointer;
}
.box >img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

@media(max-width:900px){
  .select-background-container{
    width: 80%;
  }
}

@media(max-width:768px){
  .select-background-container{
    width: 90%;
    height: 650px;
  }
  .background-container{
    grid-template-columns: 1fr 1fr;
  }
}

@media(max-width:450px){
  .box{
    width: 120px;
    height: 120px;
 }
 .select-background-container{
  height: 550px;
 }
}