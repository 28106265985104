.app-container{
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #b8c6db; */
    background-image: linear-gradient(180deg, #b8c6db 0%, #f5f7fa 74%);
}

.box-container{
    width: 400px;
    min-height:550px;
    display: flex;
    flex-direction: column;
    border-radius:19px ;
    background-color:#fff ;
    box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
    position:relative ;
    overflow: hidden;
   margin: 10px 0;
}
.backdrop{
    position: absolute;
    width: 522.36px;
    height: 450.36px;
    left: -97px;
    top: -177.47px;
    background: linear-gradient(180deg, #FFDCA6 0%, transparent 100%);
    border-radius: 177px;
    transform: rotate(525deg);
}
.tagd-logo{
    position: absolute;
width: 125px;
height: 191px;
left: 21px;
top: 27px;
}
.login-content{
    position: absolute;
    left: 156px;
    top:55px;
    padding:0 20px;
}
.header-text{
    font-size: 35px;
    font-weight: bolder;
}
.smalltext{
    font-weight: 400;
    font-size: 23px;
    z-index: 10;
   }
.sign-in-text{
    position: absolute;
    left: 35px;
    top: 230px;
    font-weight: bolder;
    font-size: 30px;
}

.reset-text{
    position: absolute;
    left: 20px;
    top: 230px;
    font-weight: bolder;
    font-size: 22px;
}
.inner-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 320px;
    margin-bottom: 30px;
}


@media(max-width:420px){
    .box-container{
        width: 95%;
        margin: 10px auto;
      }
      .signup-box-container{
        width: 95% !important;
        margin: 10px auto !important;
      }
      .tagd-logo{
    width: 110px;
    height: 176px;
    }
    .login-content{
      padding: 0;
      left: 150px;
    }
}

@media(max-width:380px)  {
    .reset-header-text{
        font-size: 25px;
    }

}

@media(max-width:350px){
   
      .tagd-logo{
    width: 80px;
    height: 140px;
    }
    .login-content{
        left: 110px;
        top:40px;
    }
    .sign-in-text{
        top: 200px;
    }
    .reset-content{
        left: 110px; 
        top:40px;
    }
    .reset-text{
        top: 200px;
    }
}

/* signup css */

.signup-app-container{
    width: 100%;
    /* height: 125vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #b8c6db;
    background-image: linear-gradient(180deg, #b8c6db 0%, #f5f7fa 74%);
}

.signup-box-container{
    width: 400px;
    min-height:900px;
    display: flex;
    flex-direction: column;
    border-radius:19px ;
    background-color:#fff ;
    box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
    position:relative ;
    overflow: hidden;
   margin: 20px 0;
}