.search {
    position: relative;  
    border: 2px solid grey;
    border-radius: 5px;
  }
  
  .search input[type="text"] {
    border: none;
    background: white;
   outline: none;
   border-radius: 5px;
   padding: 5px;
   margin: 8px 30px;
   width: 80%;
  }

  .map-search{
        position: absolute;
        top:10px;
        padding: 0 5px;
        left: 0;
  }
  .gps-search{
    position: absolute;
    top:10px;
    padding: 0 15px;
    right:0;
  }
  .gps-search:hover{
    cursor: pointer;
    color: red;
  }
  
  /* .search span,
  .search button {
    margin: 0 10px;
  } */

  @media(max-width:380px){
    .search input[type="text"]{
        width: 75%;
    }
    .gps-search{
        padding: 0 10px;
      }

  }
  @media(min-width:786px){
    .search input[type="text"]{
        width: 85%;
    }
  }
  