.ended-share-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
     background-color: #859398;
     border-radius: 10px;
        font-size: 60px !important;
        right: 20px;
   }
   .ended-share-icon:hover{
    cursor: pointer;
    background-color:#afbdc3 ;
   }
.end-container{
    width: 100vw;
    height: 100vh;
    background: white;
    position: fixed;
    top: 0;
    overflow: scroll;
}
.preview-card{
    position: relative;
    width: 60%;
    height: fit-content;
    margin: 10px auto;
    border-radius: 15px;
    background: #283048;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top , #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ 
    color: white;
}

.preview-card > h1{
    text-align: center;
    font-size: 30px;
 
    padding-top: 30px;
}
.preview-details{
    padding-top: 20px;
    display: flex; 
}

.preview-details > img{
    border-radius: 20%;
    width: 180px;
    height: 180px;
    margin: 15px 15px;
  /* transform: translateY(-40px); */
}

.profile-content{
    width: 100%;
    overflow: hidden;
}
.profile-content > h1{
    margin-top: 20px;
    font-size: 26px;
    width: 95%;
    text-transform: uppercase;
    white-space: normal;
    overflow-wrap: break-word;
}

.profile-content > h3{
    font-size: 24px;
   
}

.profile-content > p{
    font-size: 18px;
    width: 95%;
    white-space: normal;
    overflow-wrap: break-word;
}
.preview-card > h2{
    text-align: center;
    text-transform: uppercase;
  padding: 10px 0;
  font-size: 18px;
}

.end-link-container{
    width: 80%;
    border: 2px solid white;
    border-radius: 10px;
    color: white;
    padding: 5px 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin: 10px 0;
    box-shadow: 0 0 5px 0 white;
}
.end-link-container:hover{
    color: black;
    background-color: aliceblue;
}
.links-list{
 display: flex;
 flex-direction: column;
 align-items: center;
}
.single-link{
    width: 100%;
    display: flex;
    align-items: center;
}
.single-link i{
    width: 10%;
    text-align: center;
    font-size: 24px;
}
.single-link span{
    display: inline-block;
    width: 80%;
    /* text-align: center; */
    margin-left: 10px;
    text-align:center;
    font-size: 20px;
    text-transform: capitalize;
}

.end-footer{
    text-align: center;
    text-transform: uppercase;
    padding: 10px 0;
}

@media(max-width:1200px){
    .profile-content > h1{
        font-size: 24px;
    }
    
    .profile-content > h3{
        font-size: 22px;
    }
    
    .profile-content > p{
        font-size: 16px;
    }
    .ended-share-icon{
        font-size: 60px !important;
        right: 20px;
    }
}

@media(max-width:1000px){
    .preview-card{
        width: 70%;
    }
    .ended-share-icon{
        font-size: 55px !important;
    }
}


@media(max-width:768px){
    .profile-content > h1{
        font-size: 20px;
    }
    
    .profile-content > h3{
        font-size: 18px;
    }
    
    .profile-content > p{
        font-size: 14px;
    }
    .preview-card > h2{
      font-size: 14px;
    }
    .preview-details > img{
        width: 150px;
        height: 150px;
    }

    .single-link i{
        font-size: 20px;
        padding: 0 10px;
    }
    .single-link span{
        font-size: 18px;
    }

    .end-footer > h3{
    font-size: 18px;
    }
    .preview-card > h1{
        padding-top: 80px;
        font-size: 26px;
    }
    .ended-share-icon{
        font-size: 55px !important;
    }
}

@media(max-width:500px){
    .preview-card{
        width: 85%;
    }
    .profile-content > h1{
        font-size: 22px;
    }
    
    .profile-content > h3{
        font-size: 20px;
    }
    
    .profile-content > p{
        font-size: 14px;
    }
    .preview-card > h2{
      font-size: 16px;
    }
    .preview-details > img{
        width: 160px;
        height: 160px;
    }
    .ended-share-icon{
        font-size: 55px !important;
    }
}

@media(max-width:580px){
    .preview-card{
        width: 95%;
    }
    .profile-content > h1{
        font-size: 20px;
    }
    
    .profile-content > h3{
        font-size: 18px;
    }
    
    .profile-content > p{
        font-size: 12px;
    }
    .preview-card > h2{
      font-size: 14px;
    }
    .preview-details > img{
        width: 150px;
        height: 150px;
    }
    .ended-share-icon{
        font-size: 55px !important;
        top: 20px;
        right: 20px;
    }
    .preview-card > h1{
        padding-top: 80px;
    }
}



@media(max-width:500px){
    .preview-card{
        width: 100%;
    }
    .ended-share-icon{
        font-size: 50px !important;
    }
    .preview-card > h1{
        padding-top: 60px;
    }
   
}

@media(max-width:425px){
    .preview-card{
        margin: 0px;
    }

    .preview-details{
     flex-direction: column;
     align-items: center;
    }

    .preview-details > img{
        width: 130px;
        height: 130px;
    }

    .profile-content > h1{
        margin-top: 10px;
        font-size: 18px;
        padding: 0 10px;
        text-align: center;
    }
    
    .profile-content > h3{
        font-size: 18px;
        text-align: center;
    }
    
    .profile-content > p{
        font-size: 14px;
        width: 100%;
        text-align: justify;
        padding: 0 10px;
    }

    .preview-card > h2{
        font-size: 12px;
      }
      .preview-card > h1{
        padding-top: 90px;
      }
    
      
      .single-link i{
        font-size: 18px;
        padding: 0 10px;
    }
    .single-link span{
        font-size: 16px;
    }

    .end-footer > h3{
    font-size: 16px;
    }
    .end-link-container{
        width: 90%;
    }
    .ended-share-icon{
        position: absolute;
        right: 10px;
        top: 10px;
          font-size: 50px !important;
         margin: 10px;
         background-color: #859398;
         padding: 10px;
         border-radius: 10px;
       }
}