.resetContainer {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 231, 231);
    position: fixed;
    top: 0%;
    left: 0;
  }

  .resetBox {
    background-color: white;
    width: 25vw;
    height: 70vh;
    box-sizing: border-box;
    overflow: hidden;
  }

  .resetBox > h2{
    text-align: center;
    color: rgba(0, 0, 0, 0.664);
    font: 400 2vmax "Roboto";
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.205);
    width: 90%;
    margin: auto;
  }
  .resetPasswordForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 1vmax;
    justify-content: space-evenly;
    height: 70%;
    transition: all 0.5s;
  }
  .resetPasswordForm .input-box{
    width: 90%;
  }

  .resetPasswordForm .input-box-error{
    width: 90%;
  }


  .resetPasswordBtn {
    border: none;
    background-color: tomato;
    color: white;
    font: 300 1.2vmax "Roboto";
    width: 90%;
    padding: 0.8vmax;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
  }

  @media(max-width:768px){
    .resetBox {
      width: 55vw;
      height: 70vh;
    }
    .resetBox > h2{
      text-align: center;
      color: rgba(0, 0, 0, 0.664);
      font: 400 3vmax "Roboto";
    padding: 10px;
    width: 90%;
    }

    .resetPasswordBtn {
      
      font: 300 2vmax "Roboto";
      width: 80%;
    
    }
  }

  @media(max-width:450px){
    .resetBox {
      width: 80vw;
      height: 70vh;
    }
    .resetBox > h2{
      text-align: center;
      color: rgba(0, 0, 0, 0.664);
      font: 400 4vmax "Roboto";
       width: 90%;
    }

    .resetPasswordBtn {
      
      font: 300 4vmax "Roboto";
      width: 90%;
    
    }
  }