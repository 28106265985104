.create-link-container{
    border: 2px solid  rgb(35, 64, 253); 
   padding-bottom: 20px;
   border-radius: 20px;
   margin-top: 10px;
}

.link-field{
    position: relative;
    width: 85%;
    margin-bottom: 25px;
}
.link-field-error{
    position: relative;
    width: 80%;
    margin-bottom: 25px;
}

.link-field-input{
    height: 35px;
    width: 100%;
    border: none;
    border-bottom:2px solid silver ;
    outline: none;
}

.link-field-error input{
    border-bottom: 2px solid red;
 }

 .link-field label{
    position: absolute;
    left: 0;
    bottom: 20px;
    margin-bottom: 5px;
    color: grey;
    pointer-events: none;
    font-weight: bolder;
    transition: all 0.3s ease;
    font-size: 15px;
   } 

   .link-field-error label{
    position: absolute;
    left: 0;
    margin-bottom: 5px;
    color: red;
    pointer-events: none;
    font-weight: bolder;
    transition: all 0.3s ease;
    font-size: 15px;
}

.link-field-input:focus ~ label,
   .link-field-input:not(:placeholder-shown).link-field-input:not(:focus) ~ label
   {
       transform: translateY(-25px);
       color: blue;
       font-weight: bolder;
   }
   
.link-field-error .link-field-input:focus ~ label,
.link-field-error .link-field-input:not(:placeholder-shown).link-field-input:not(:focus) ~ label
{
    transform: translateY(-25px);
    color: red;
    font-weight: bolder;
}

.link-field-input:focus ~ label .label-hide,
.link-field-input:not(:placeholder-shown).link-field-input:not(:focus) ~ label .label-hide
{
   display: none;
}

.url-field{
    position: relative;
    width: 85%;
    margin-bottom: 25px;
}
.url-field label{
    position: absolute;
    left: 0;
    margin-bottom: 5px;
    color: grey;
    pointer-events: none;
    font-weight: bolder;
    transition: all 0.3s ease;
    font-size: 15px;
   } 
   .url-field-error label{
    position: absolute;
    left: 0;
    margin-bottom: 5px;
    color: red;
    pointer-events: none;
    font-weight: bolder;
    transition: all 0.3s ease;
    font-size: 15px;
   } 
.url-field-error{
    position: relative;
    width: 80%;
    margin-bottom: 25px;
}
.url-field-input{
    height: 35px;
    width: 100%;
    border: none;
    border-bottom:2px solid silver ;
    outline: none;
}
.url-field-error input{
    border-bottom: 2px solid red;
 }

.url-field-input:focus ~ label,
.url-field-input:not(:placeholder-shown).url-field-input:not(:focus) ~ label
{
    transform: translateY(-25px);
    color: blue;
    font-weight: bolder;
}

.url-field-error .url-field-input:focus ~ label,
.url-field-error .url-field-input:not(:placeholder-shown).url-field-input:not(:focus) ~ label
{
    transform: translateY(-25px);
    color: red;
    font-weight: bolder;
}



.url-field-input:focus ~ label .label-hide,
.url-field-input:not(:placeholder-shown).url-field-input:not(:focus) ~ label .label-hide
{
   display: none;
}

.add-link-button{
    width: 70%;
    margin: 10px auto;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 800;
    border: none;
    padding: 2px 0;
    box-shadow: 0 0px 3px 0 black;
    background-color: rgb(35, 64, 253);
    color: white;
}

@media(max-width:769px){
   
    .link-field{
        width: 100%;
        margin: 20px auto;
    }
    .url-field{
        width: 100%;
        margin: 20px auto;
    }
    
    .add-link-button{
        width: 80%;
    }
    .link-field-error{
        width: 90%;
        margin: 0 auto;
    }
    .url-field-error{
        width: 90%;
        margin: 0 auto;
    }
}