.container-cropper{
    height: 85%;
    
  }

  .container-button{
    height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  }

  .options-buttons{
    height: 50px; 
   font-weight: bold;
   font-size: 20px;
   padding: 0 30px;
  }

  .cropper{
    position: relative;
     width: 80%;
     margin: auto;
    height: 90%;
  }
  .slider{
    margin: auto;
    display: flex;
    align-items: center;
    width: 50%;
    height: 10%;
    justify-content: center;
  }

  .slider span {
    margin-left: 20px;
    font-size: 30px;
    font-weight: bolder;
    color: #fff;
}


.slider input {
    margin-left: 15px;
    margin-top: 5px;
}

.reactEasyCrop_Container{
  top: 30px !important;
}

@media(max-width:768px) {
  .cropper{
    width: 90%;
    margin-top: 50px;
  }
}

@media(max-width:450px) {
  .container-cropper{
    height: 70%;
  }
  .cropper{
height: 90%;
  }
  .options-buttons{
    height: 50px; 
   font-weight: bold;
   font-size: 15px;
   padding: 0 25px;
  }
}
@media(max-width:350px) {
  .options-buttons{
    height: 40px; 
   font-weight: bold;
   font-size: 15px;
   padding: 0 15px;
  }
}