.album-container {
    background-color: rgba(255, 255, 255, 0.95); /* Light transparent background */
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: #333;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
  }

.album-header{
    margin-top: 25px;
    text-align: center;
    text-transform: capitalize;
}

.add-images-button {
    position: fixed;
    bottom: 20px;
    width: 80%;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }

  .image-wrapper {
    width: 90%;
    position: relative;
    display: inline-block;
    margin: 10px ;
    
  }

.uploaded-image{
  width: 100%;
  height: 150px;
  margin: 5px 0;
  border-radius: 10px;
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 30px !important;
  height: 30px !important;
}
.images-container{
  /* background-color: #007bff; */
  width: 80%;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
}

.dragabble-handle{
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 35px !important;
  height: 35px !important;
}

.slider-description{
  width: 80%;
text-align: center;
text-transform: capitalize;
color:rgb(64, 63, 63);
font-weight: 500;
}

@media(min-width:374px){
  .album-header{
    margin-top: 40px;
    font-size: 28px;
}
.slider-description{
  font-size: 18px;
}
.image-wrapper {
  width: 85%;
}
.uploaded-image{
  height: 160px;
}
.delete-icon {
  width: 35px !important;
  height: 35px !important;
}
.dragabble-handle{
  width: 40px !important;
  height: 40px !important;
}
}

@media(min-width:425px){
  .album-header{
    margin-top: 45px;
}

.image-wrapper {
  width: 80%;
}
.uploaded-image{
  height: 170px;
}
.add-images-button{
  width: 75%;
}
}


@media(min-width:768px){
  .album-header{
    margin-top: 50px;
    font-size: 34px;
}
.slider-description{
  font-size: 20px;
}
.image-wrapper {
  width: 60%;
}
.uploaded-image{
  height: 220px;
}
.delete-icon {
  width: 40px !important;
  height: 40px !important;
}
.dragabble-handle{
  width: 45px !important;
  height: 45px !important;
}
.add-images-button{
  width: 60%;
}
}


@media(min-width:1000px){
  .album-header{
    margin-top: 55px;
    font-size: 36px;
}
.slider-description{
  font-size: 22px;
}
.image-wrapper {
  width: 45%;
}
.uploaded-image{
  height: 240px;
}
.delete-icon {
  width: 45px !important;
  height: 45px !important;
}
.dragabble-handle{
  width: 50px !important;
  height: 50px !important;
}
.add-images-button{
  width: 45%;
  font-size: 20px;
  font-weight: bold;
}
}

@media(min-width:1300px){
  .album-header{
    margin-top: 60px;
    font-size: 38px;
}
.slider-description{
  font-size: 24px;
}
.image-wrapper {
  width: 40%;
}
.uploaded-image{
  height: 260px;
}
.delete-icon {
  width: 45px !important;
  height: 45px !important;
}
.dragabble-handle{
  width: 50px !important;
  height: 50px !important;
}
.add-images-button{
  width: 45%;
  font-size: 20px;
  font-weight: bold;
}
}