.landing-container{
    width: 100%;
    /* height: 100%; */
    height: 100vh;
    background-color: rgb(92, 130, 253);  
    border-radius: 0px 0px 30px 30px;
   transform: translateY(-100px);
   padding: 20px;
}

.landing-container-ended{
    background-color: #d8b4e2; 
}
.buttonended{
    background-color: #a02fbf !important;
}
.landing-div{
    display: flex;
  padding-top: 100px;
}

.landing-content{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.landing-icon{
    font-size: 25px !important;
    margin-left: 20px;
}
.landing-content > h1{
    width: 80%;
    font-size: 45px;
    margin-bottom: 30px;
    color: white;
}
.landing-content> h3{
    width: 80%;
    font-size: 32px;
    text-align: left;
    color: white;
    text-transform: capitalize;
    line-height: 40px;
}

.landing-content > button{
    width: 65%;
    border: 0;
    padding: 10px;
    margin: 15px 0;
    border-radius: 15px;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: bolder;
    background-color: transparent;
    border: 2px solid white;
    color: white;
}
.landing-content > button:hover{
    background-color: white;
    color: black;
}


.landing-image{
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 30px;
    /* background-color: aqua; */
    margin-right: 20px;
  }

  .landing-image img {
     width: 30%;
     height: 400px;
     object-fit: contain;
  }
.landing-heading{
    text-align: center;
    transform: translateY(-100px);
    margin: 15px 0;
    text-transform: uppercase;
}

.single-card{
    width: 25%;
    height: 420px;
    background-color: whitesmoke;
    border-radius: 5px;
    box-shadow: 0 0 4px 0px grey;
}
.cards-container{
 display: flex;
 justify-content: space-around;
 margin: 10px 0;
 transform: translateY(-80px);
}

.single-card > img{
    width: 95%;
    height: 220px;
    display: block;
    object-fit: contain;
    border-radius: 4px;
    margin: 10px auto;
  
}
.single-card > h3{
    font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
  text-transform: uppercase;
}
.single-card >p{
    line-height: 1.5;
  margin: 5px auto;
  text-align: center;
  width: 80%;
  font-size: 18px;
  text-transform: capitalize;
  /* background-color: aqua; */
}

.single-card > a{
    display: block;
    margin: 10px auto;
    width: 90%;
   background-color: rgb(92, 130, 253);
    padding: 8px;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
}
  
  @media(max-width:1200px){
  
    .landing-content > h1{
        font-size: 45px;
    }
    .landing-content> h3{
        font-size: 26px;
        line-height: 40px;
    }
   
    .landing-content > button{
       width: 85%;
    }
    .landing-image{
        margin-top: 30px;
      }
    
      .landing-image img {
       width: 35%;
       height: 350px;
       object-fit: contain;
      }
      .single-card{
        height: 440px;
      }
  }

  @media(max-width:1000px){
    .landing-content{
        margin-top: 40px;
    }
    .landing-content > h1{
        font-size: 35px;
    }
    .landing-content> h3{
        font-size: 22px;
        line-height: 35px;
    }
    .landing-content > button{
       width: 85%;
    }
    
    .landing-image{
        margin-top: 20px;
      }
    
      .landing-image img {
       width: 35%;
       height: 300px;
      }

      .single-card{
        width: 30%;
    }

    .single-card > h3{
        font-size: 16px;
      font-weight: bold;
      margin: 10px 0;
      text-align: center;
      text-transform: uppercase;
    }
    .single-card >p{
      font-size: 16px;
    }
    
    .single-card > a{
        font-size: 16px;
    }

  }
  @media(max-width:520px){
  .landing-image > img{
 width: 35%; 
 height: 250px; 
}
  }

  @media(max-width:768px){
    .landing-container{
        height: 100%;
    }
    .landing-div{
        flex-direction: column;
    }
    .landing-image{
        order: 1;
        width: 80%;
        margin: 30px auto;
    }
    .landing-content > button{
        width: 80%;
    }
  
    .landing-content{
        order: 2;
        margin-top: 0;
    }
    .cards-container{
        flex-direction: column;
        align-items: center;
    }
    .single-card{
        width: 60%;
        margin: 10px 0;
        height: 470px;
    }
    .single-card > img{
        height: 250px;
    }
    .single-card >h3{
        font-size: 22px;
    }
    .single-card >p{
        font-size: 20px;
        width: 80%;
    }
    .single-card >a{
        font-size: 20px;
        width: 80%;
    }
  }

  @media(max-width:570px){
    .single-card{
        width: 75%;
    }
  }

@media(max-width:425px){
    .landing-container{
        height: 100vh;
    }
    .landing-image{
       width: 100%;
       margin: 0 auto;
       /* background-color: aqua; */
    }
    .landing-image >img{
        width: 30%;
        height: 250px;
    }
    .landing-content{
      align-items: start;  
      width: 100%;
      margin-left: 10px;
        /* background-color: aqua; */
    }
   
    .landing-content > h1{
        font-size: 34px;
        width: 100%;
    }
    .landing-content > h3{
        font-size: 22px;
        width: 100%;
    }
    .landing-content > button{
        width: 90%;
        font-size: 18px;
    }
    .landing-icon{
        font-size: 20px !important;
    }

    .single-card{
        width: 80%;
        margin: 10px 0;
        height: 400px;
    }
    .single-card > img{
        height: 200px;
    }
    .single-card >h3{
        font-size: 20px;
    }
    .single-card >p{
        font-size: 18px;
        width: 90%;
    }
    .single-card >a{
        font-size: 18px;
        width: 85%;
    }

}

@media(max-width:375px){
    .landing-image{
       width: 100%;
       margin: 0 auto;
       /* background-color: aqua; */
    }
    .landing-image >img{
        width: 35%;
    }
    .landing-content{
      margin-left: 0px;
    }
   
    .landing-content > h1{
        font-size: 30px;
    }
    .landing-content > h3{
        font-size: 20px;
    }
    .landing-content > button{
        font-size: 16px;
    }
    .landing-icon{
        font-size: 18px !important;
    }
    .single-card{
        width: 90%;
        margin: 10px 0;
        height: 370px;
    }
    .single-card > img{
        height: 180px;
    }
    .single-card >h3{
        font-size: 18px;
    }
    .single-card >p{
        font-size: 16px;
        width: 80%;
    }
    .single-card >a{
        font-size: 16px;
        width: 85%;
    }
}

