.link-container
{
    border: 2px solid blue;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.links-dots{
    display: flex;
    min-height:50px ;
    align-items: center;
    border-right: 2px solid blue;
}

.dot-icon{
    font-size: 50px;
    margin: 5px 0;
   }

   .link-part{
    width: 90%;
    padding: 5px 0;
}

.edit-linkTitle{
    display: flex;
    width: 90%;
   margin: 5px;
 border-bottom: 2px solid blue;
}

.link-edit-icon{
    display: flex;
   width: 100%;
   align-items: center;
}
.edit-linkTitle-wrap{
    width: 90%;
    margin-top: 8px;
    margin-left: 8px;
  }

  .edit-url{
    margin: 5px ;
    display: flex;  
    width: 81%;
}
.edit-url-wrap{
    width: 90%;
    white-space: nowrap;
    margin-top: 6px;
    margin-left: 5px;
    overflow: hidden;
}

.delete-button{
    position: absolute;
    right: 10px;
    top: 10px;
}

.edit-link-input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    border-bottom-color: #39e09b;
    width: 80%;
  }
  .error-url-text {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    border-bottom-color: red;
    outline: none;
    width: 80%;
  
  }

  .error-msg-appreance{
    padding: 5px 0;
    text-transform: capitalize;
  }
  .error-msg-profile{
    color: red;
    font-weight: 700;
  }
  .edit-link-input:focus {
    outline: none;
  }
  .error-url-text::placeholder {
    color: rgb(204, 0, 0);
  }
  .error-msg-link{
    color: red;
    font-weight: bold;
    display: block;
    margin: 5px 3px;
  }

  @media screen and (max-width:481px) {
    .edit-linkTitle{
        width: 80%;
    }
}