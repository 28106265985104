.tnc-line{
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.tnc-checkbox{
   margin-left: 3px;
   width: 18px;
   height: 18px;
    /* margin-top: 3px; */
}

.login-form-container{
    width: 100%;
     display: flex;
    flex-direction: column; 
}
.input-box{
    position: relative;  
    margin: 12px;
    border: 2px solid grey;
    border-radius: 5px;
  }
  .input-box-error{
    position: relative;  
    margin: 12px;
    border: 2px solid red;
    border-radius: 5px;
  } 
  .input-box-error .label-icon{
    color: red;
   
}
.input-box-error .input-label{
    color: red;
}
.input-box-error .error-label-icon{
    display: block;
}

  .input-field-login{
    border: none;
    background: white;
   outline: none;
   border-radius: 5px;
   padding: 5px;
 margin: 8px 30px;
   width: 80%;
}

.input-label{
    position: absolute;
    top:13px ;
    left: 40px;
    transition:.3s ;
}

.input-field-login:focus ~ .input-label,
.input-field-login:not(:placeholder-shown).input-field-login:not(:focus) ~ .input-label
{
top:-13px;
left:40px;
font-size: 14px;
background:white ;
}

.label-icon{
    position: absolute;
    top:10px;
    padding: 0 5px;
    left: 0;
}
.error-label-icon{
    color: red;
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
}
.forgot-password{
    font-size: 15px;
    color: blue;
    font-weight: 500;
    text-decoration: none;
    margin-left: 20px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 5px;
}

.login-submit-button{
    width: 90%;
    background: blue;
    border: none;
    margin: 10px auto;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
     color: white;
     font-size: 20px;
      font-weight: 400;
    position: relative;
}
.login-submit-button-disabled{
    width: 90%;
    background: blue;
    opacity: 0.6;
    border: none;
    margin: 10px auto;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
     color: white;
     font-size: 20px;
      font-weight: 400;
    position: relative;
}
.login-text{
    padding: 0 40px;
}
.forward-icon{
    padding: 0 15px;
}
.error-msg {
    color: red;  
    font-weight: 700;
    display: block;
    margin: 8px 20px;
  }

  @media(min-width:768px){
    .tnc-line{
        margin-left: 30px;
        display: flex;
        align-items: center;
    }
  }