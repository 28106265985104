.profile-container{
    background: rgb(237, 248, 255);
    border-radius: 20px;
    margin-top: 10px;   
  margin: 10px auto;
  padding: 15px 0;
}
.avatar-container{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 15px 0; */
    height: 100%;
  }

  .avatar{
    position: relative;
     width: 250px;
     height: 250px;
  }

  .avatar > img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  .camera-icon{
  position: absolute !important;
  bottom: 10px;
  right:20px ;
  height: 4rem;
  width: 4rem;
  background: whitesmoke !important;
  }

  .avatar-preview-container{
    background-color: rgba(0, 0,0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .cancel-icon{
    font-size: 35px;
    position: absolute;
    top: 10px;
    right: 40px;
  }
  .cancel-icon:hover{
     color: red;
  }

  .view-img-container img{
     margin: auto;
     width:   60%;
     height: 60%;
  }

  .detail-heading{
    font-size: 18px;
    font-weight: bolder;
 margin-top: 20px;
}

.detail-link-input{
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border-bottom-color: #39e09b;
  width: 80%;
}
.detail-link-input:focus{
  outline: none;
}
.bio-container{
  width: 80%;
  word-break: break-all;
}
#bio{
  resize: none;
   width: 85%;
}

@media(max-width:1000px){
  .avatar{
    position: relative;
     width: 200px;
     height: 200px;
  }
  .camera-icon{
    height: 3rem;
    width: 3rem;
  }
}

@media(max-width:768px){
 .detail-container{
   padding-left: 30px;
   padding-top: 20px;
 }

}

@media(max-width:570px){
  .detail-container{
    padding-left: 30px;
    padding-top: 20px;
  }
  .detail-link-input{
    width: 90%;
  }

  .bio-container{
    width: 90%;
  }

  .view-img-container img{
    margin:70px auto;
    width:   70%;
    height: 60%;
 }
 }


 @media(max-width:425px){
  .view-img-container img{
    margin:100px auto;
    width:   80%;
    height: 50%;
 }
 }