/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .help-section {
    text-align: center;
    padding: 10px;
    background-color: #f8f9fa;
    margin-top: 30px;
  }
  .help-section > p{
margin-top: 5px;
margin-bottom: 0;
  }
  
  .help-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
  }
  
  .footer {
    background-color: #343a40;
    color: white;
    padding: 20px 10px;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .contact-info {
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .contact-link {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    /* font-weight: bold; */
  }
  
  .contact-link i {
    margin-right: 5px;
  }
  
  .footer-note {
    font-size: 20px;
  }
  
  /* Responsive Styles */
  @media (max-width: 769px) {
    .contact-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .contact-link {
      margin: 5px 0;
    }
  }
  @media (max-width: 375px) {
    .contact-info {
     
        font-size: 14px;
      }
  }
  