body{
    font-family: 'Roboto', sans-serif !important;
    /* font-family: 'Grape Nuts, sans-serif' !important ; */
    font-weight: 400; /* Regular */
}

.error-msg{
    color: red;
}
.notfound-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    background-color: #f5f5f5; 
    color: #333; 
    font-size: 24px; 
    text-transform: uppercase;
    color: rgb(103, 102, 102);
}
input[type="color"] {
    -webkit-appearance: none;
    border-radius: 0;
}