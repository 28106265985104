.nav-link.menu_active {
    border-bottom:3px solid blue ;
  }
  .navbar{
      border-radius: 0px 0px 19px 19px;
      box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 50%);
      z-index: 1;
  }
  
  
  .navbar-light .navbar-nav .nav-link {
      color:  black;
      margin: 0 20px;
      text-align: left;
      font-size: 16px;
      font-weight: bolder;
      text-transform: uppercase;
  }
  
  .navbar-brand img{
      height: 40px;
      width: 120px;
      margin-left: 10px;
  }

  .navbar-nav .dropdown-item{
    font-size: 14px;
    font-weight: 600;
    font-family: 'Lora', serif;
}

  .menu_active{
      font-weight: bold;
      border-bottom:1px solid red ;
  }
  
  .logout-button{
      /* color:  black;
      margin: 0 20px;
      text-align: left;
      font-size: 16px;
      font-weight: bolder;
      text-transform: uppercase; */
      background-color: transparent;
      border: 0;
      outline: 0;
      font-size: 16px;
      color:  black;
      font-weight: bolder;
      text-transform: uppercase;
      text-align: left;
      padding: 0 10px;
      margin: 5px 10px;
  }
  
  @media (min-width:375px){
      .navbar-brand img{
          height: 50px;
          width: 140px;
      }
      .navbar-nav .dropdown-item{
        font-size: 16px;
    }
  }
  
  @media (min-width:768px){
      .navbar-brand img{
          height: 60px;
          width: 160px;
      }
      .navbar-light .navbar-nav .nav-link {
          font-size: 20px;
      }
      .logout-button{
          font-size: 20px;
      }

      .navbar-nav .dropdown-item{
        font-size: 20px;
        padding: 10px 30px;
    }
     
  }
  
  @media (min-width:1000px){
      .logout-button{
        margin-top: 8px;
      }
      .navbar-nav .dropdown-item{
        font-size: 18px;
        padding: 10px 20px;
      
    }
    .dropdown-menu[data-bs-popper] {
        left: -60px !important;
    }
  }
  
  
  
  
  