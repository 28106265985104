.default-banner-button
.row-containers{
  justify-content: center;
  align-items: center;
}

.align-bottom{
  top:340px
}
.align-top{
  top:25px
}
.wed-details-container.align-top{
  padding-top: 10px;
}

.preview-custom-icon{
  width: 140px !important;
  height: 60px !important;
}
.error-msg-wedding{
  color: red;
  font-weight: bolder;
  display: block;
}
.error-wedding-div{
 padding-bottom: 15px;
}
.form-screen{

    width: 100%;
    height: 100%;
}
.form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    border: 2px solid blue;
    border-radius: 5px;
    margin: 30px 0px;
    order: 1;
}

.form-div{
    width: 100%;
    margin-top: 10px;
}
.form-input-div{
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 15px;
}

.form-input-div-error{
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 15px;
}
.extra-label-detail{
  display: block;
  margin-bottom: 45px;
  font-size: 18px;
  font-weight: 500;
}
.form-input-field{
    height: 35px;
    width: 100%;
    border: none;
    border-bottom:2px solid silver ;
    outline: none;
    background: transparent;
    color: black;
}

.form-input-field-error{
  height: 35px;
  width: 100%;
  border: none;
  border-bottom:2px solid red ;
  outline: none;
  background: transparent;
  color: black;
}
.form-input-div label{
    position: absolute;
    left: 0;
    bottom: 5px;
    margin-bottom: 5px;
    color: grey;
    pointer-events: none;
    font-weight: bolder;
    transition: all 0.3s ease;
    font-size: 15px;
   } 

   .form-input-div-error label{
    position: absolute;
    left: 0;
    bottom: 5px;
    margin-bottom: 5px;
    color: red;
    pointer-events: none;
    font-weight: bolder;
    transition: all 0.3s ease;
    font-size: 15px;
   } 
   .form-input-field:focus ~ label,
   .form-input-field:not(:placeholder-shown).form-input-field:not(:focus) ~ label
   {
       transform: translateY(-25px);
        color: blue;
       font-weight: bolder;
   }
   .form-input-field:focus ~ label .label-hide,
   .form-input-field:not(:placeholder-shown).form-input-field:not(:focus) ~ label .label-hide
   {
      display: none;
   }
   .form-input-field-error:focus ~ label,
   .form-input-field-error:not(:placeholder-shown).form-input-field-error:not(:focus) ~ label
   {
       transform: translateY(-25px);
        color: red;
       font-weight: bolder;
   }
   .wedding-date-input{
    background-color: transparent;
    width: 100%;
    border: 0;
    border:2px solid black ;
    height: 40px;   
    border-radius: 5px;
    color: black;
    margin-bottom: 15px;
    padding: 10px;
   }
   .file-input > label{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    color: blue;
   }


   .date-container >label{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    color: blue;

   }
   .banner-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
   }

   .small-i{
    margin-top: 10px;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
   }
  .default-banner-button{
    background: transparent;
    outline: 0;
    border: 0;
    width: 90%;
    height: 40px;
    border-radius: 5px;
    color: white;
    background: blue;
    font-weight: bolder;
  }
  .default-banner-button > i{
    font-size: 20px;
    transform: translate(-8px,1px);
  }
  .banner-buttons>span{
    font-size: 20px;
    font-weight: bolder;
    margin: 10px ;
  }
  .custom-file-input{
    width: 90%;
    background: transparent;
    color: black;
  }
  .form-submit-button{
    margin:30px 0;
    background: blue; 
    outline: 0;
    border: 0;
    width: 100%;
    color: white;
    height: 40px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bolder;
  }
  .align-div{
    margin:10px 0 ;
  }
  .align-div > label{
    font-weight: bold;
    margin-bottom: 10px;
    color: blue;
  }
  .form-heading{
    color: black;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 16px;
  }

  .preview-container{

  /* color: white; */
  border-radius: 5px ;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  height: 500px;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  }
  .preview-container-bg{
    border: 2px solid white;
    border-radius: 5px;
    margin-bottom: 10px;

  }
  .preview-container > img{
    width: 100%;
    height: 100%;
    border: 2px solid black;
    /* object-fit: cover; */
  }
  .wed-details-container {
    position: absolute;
    bottom: 0px;
    /* top: 50px; */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.wed-details-container{
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0,1)90%); 
}
.wed-details-container.align-top{
  background: linear-gradient(to top, transparent, rgb(3, 2, 1) 90%); 
}

.wed-detail-name{
    font-size: 18px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: bold;
}


.wed-detail-date {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.wed-detail-date::before,
.wed-detail-date::after {
    content: "";
    display: block;
    width: calc(30% - 10px); /* Adjust the width as needed */
    height: 4px; /* Adjust the height of the horizontal lines */
    background-color: white; 
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}

.wed-detail-date::before {
    left: 0;
}

.wed-detail-date::after {
    right: 0;
}

.wed-detail-hastag{
    text-transform: uppercase;
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
}

.form-double-buttons{
    /* background-color: aqua; */
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}
.form-double-buttons > button{
    /* margin:30px 0; */
    background: blue; 
    outline: 0;
    border: 0;
    width: 45%;   
    height: 40px;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: bolder;
}





/* css second form */
.bride-groom-container{
    display: flex;
    flex-direction: column;
    align-items: center;
     border:  2px solid black;
     border-radius: 5px;
}
.bride-groom-div{
    background-color:  whitesmoke;
    box-shadow: 0 0 4px 0 gray;
    padding: 10px 0;
    margin-bottom: 15px;
    width: 90%;
    margin: 15px auto;
    border-radius: 10px
}
.heart-icon{
    font-size: 70px !important;
    color: red;
}
.bride-groom-div > img{
    height: 90%;
    width: 90%;
    display: block;
    margin: auto;
}

.bride-groom-div > h3,h4{
  text-align: center;
  text-transform: uppercase;
  width: 90%;
  margin:10px auto;
  word-wrap: break-word;
}
.bride-groom-div >h3{
font-size: 16px;
}
.bride-groom-div >h4{
font-size: 14px;
}


/* css third form */
.condition-form-div{
    margin: 15px 0;
    display: flex;
   
}
.condition-form-div > label{
    display: flex;
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
}
.condition-form-div > label >input {
height: 18px;
width: 18px;
}
.date-condition-div > label >input{
    background-color: transparent;
    /* width: 100%; */
    border: 0;
    border:2px solid black ;
    /* height: 40px;    */
    border-radius: 5px;
    color: black;
    padding:10px;
    margin-bottom: 10px;
}

/* .background-container-theme{
    margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  justify-items: center;
  background:  #4b4343;
  padding: 15px;
  border-radius: 10px;
}  */

.background-container-theme {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto; /* Adjust the rows to accommodate headings */
  grid-gap: 10px;
  justify-items: center;
  background: #4b4343;
  padding: 15px;
  border-radius: 10px;
}

.theme-heading {
  grid-column: span 3; /* Make the heading span all three columns */
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 10px 0;
  text-transform: uppercase;
}
.theme-box{
    width: 60px;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
}
.theme-box >img{
    width: 100%;
    height: 100%;
    border-radius:  10px;
  }

  .background-container-theme .active-theme{
    border: 3px solid gold;
    border-radius: 10px;
  }
  .form-heading-theme{
    margin-top: 10px;
    text-align: center;
  }
  

  .religious-element-container {
    margin-top: 20px;
  }
  
  .icon-selection {
    display: flex;
    align-items: center;
    justify-content: center;
  }
 .edit-reli-icon{
  align-self: flex-end;
  width: 25px !important;
  height: 25px !important;
  color: #0000FF;
 }
 .edit-reli-icon:hover{
  color: #070782;
 }
  .religious-element-container #svg-size{
    width: 100%;
    height: 100%;
  }
  #svg-size{
    width: 100%;
    height: 100%; 
  }
  .icon-display {
    width: 60px;
    height: 60px;
    margin: 10px 0;
  }

  
  .icon-options {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .icon-option {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    transition: border-color 0.3s ease;
    position: relative;
    z-index: 1;
  }
  
  .icon-option:hover {
    border-color: #007bff;
  }
  
  .religious-text-field > textarea{
    resize: none;
  }
  .popover-header{
    background-color: #4b4343 !important;
    color: white;
  }
  .popover-body{
    background-color: whitesmoke !important;
    font-size: 16px;
  }
  .popover-link{
    color: orangered;
  }
  .pre-small{
    display: block;
    margin-top: 10px;
    font-size: 14px;
  }
  .color-picker{
    width: 95%;
    height: 30px;
    margin: auto;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid black;
  }
  .blessing-label{
    font-weight: bold;
    color: #0000FF;
    margin: 10px 0;
  }
  .blessing-input-feild{
    width: 95%;
    height: 120px;
    resize: none;
    margin-bottom: 10px;
  }
 .blessing-input-error{
  border: 1px solid red;
 }
 .blessing-label-error{
  color: red;
 }
 .preview-banner-overlay{
  position: absolute;
  top: 28px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0,0.1); 
  z-index: 1; 
 }
  @media(min-width:375px){
    .wed-detail-name{
      font-size: 20px;
    }
    .wed-detail-date{
      font-size: 16px;
    }
    .wed-detail-hastag{
      font-size: 14px;
    }
    .form-heading{   
    font-size: 18px;
    }
    .extra-label-detail{
      margin-bottom: 25px;
    }
    .theme-box{
      width: 70px;
      height: 70px;
  }

  .theme-heading {
    font-size: 20px;
  }



 .edit-reli-icon{
  width: 28px !important;
  height: 28px !important;
 
 }

  
  .icon-display {
    width: 70px;
    height: 70px;
  }

  .religious-text-field{
    width: 85%;
    margin: 0 auto;
  }
  .pre-small{
    display: block;
    margin-top: 15px;
    font-size: 14px;
  }

  .preview-custom-icon{
    width: 200px !important;
    height: 90px !important;
  }
  .align-bottom{
    top:300px
  }
  .small-i{
    margin-top: 15px;
    font-size: 15px;
   }
   .default-banner-button > i{
    font-size: 22px;
    transform: translate(-18px,2px);
  }
  }
  
  
  @media(min-width:425px){
    .preview-banner-overlay{
      top:32px
    }
    .wed-detail-name{
      font-size: 22px;
    }
    .wed-detail-date{
      font-size: 16px;
    }
    .wed-detail-hastag{
      font-size: 16px;
    }
    .bride-groom-div > img{
   width: 85%;
   height: 85%;
    } 
    
    .bride-groom-div >h3{
      font-size: 16px;
    }
    .bride-groom-div >h4{
      font-size: 14px;
    }
    .form-heading{   
      font-size: 20px;
      }

      .theme-box{
        width: 80px;
        height: 80px;
    }


   .edit-reli-icon{
    width: 28px !important;
    height: 28px !important;
   
   }

    
    .icon-display {
      width: 80px;
      height: 80px;
      margin: 10px 0;
    }
  
    
    
    .icon-option {
      width: 65px;
      height: 65px;
    }
    .religious-text-field{
      width: 80%;
      margin: 0 auto;
    }
    .pre-small{
      display: block;
      margin-top: 15px;
      font-size: 16px;
    }
    .blessing-input-feild{
      width: 85%;
      height: 140px;
    }
  }
  
  @media(min-width:768px){
    .wed-detail-name{
        font-size: 26px;
    }
    .wed-detail-date {
        font-size: 20px;
    }
    .wed-detail-hastag{
      font-size: 20px;
    }
    .wed-details-container > img{
      width: 140px;
    }
    .form-heading{   
      font-size: 22px;
      }
      .religious-text-field{
        width: 60%;
        margin: 0 auto;
      }
      .blessing-input-feild{
        width: 75%;
        height: 150px;
      }
      .preview-custom-icon{
          width: 220px !important;
          height: 100px !important; 
      }
      .preview-banner-overlay{
        top:34px
      }
      .align-bottom{
        top:270px
      }
      .small-i{
        margin-top: 15px;
        font-size: 18px;
       }
       .default-banner-button > i{
        font-size: 24px;
        transform: translate(-30px,2px);
      }
  }
  

@media(min-width:768px){
    .condition-form-div > label{
        font-size: 22px;
      }
      .condition-form-div > label >input {
        height: 22px;
        width: 22px;
        }
        .theme-box{
            width: 100px;
            height: 100px;
            /* border-radius: 10px;
            cursor: pointer; */
        }
        .date-condition-div >label>input{
            margin-left: 20px;
            height: 40px;   
            width: 300px;
        }

        .bride-groom-div >h3{
          font-size: 18px;
        }
        .bride-groom-div >h4{
          font-size: 16px;
        }
       
      .bride-groom-div >img{
        height: 300px;
      }
      .heart-icon{
        font-size: 100px !important;
      }
      .bride-groom-container{
        display: flex;
        flex-direction: row;
        padding: 10px;
      }
      .bride-groom-div{
        height: 500px;
        width: 40%;
      }
      .theme-heading{
        font-size: 24px;
      }

      .religious-element-container {
        margin-top: 25px;
      }
      
     .edit-reli-icon{
      align-self: flex-end;
      width: 30px !important;
      height: 30px !important;
     
     }
      
      .icon-display {
        width: 90px;
        height: 90px;
        margin: 10px 0;
      }
    
      .icon-option {
        width: 70px;
        height: 70px;
      }
      .error-center{
        text-align: center;
      }
      .pre-small{
        display: block;
        margin-top: 15px;
        font-size: 18px;
      }
     
}


@media (min-width:1000px){
   .row-containers{
    align-items: flex-start;
  margin: 0 10px;
  }
  .preview-container{
    order: 1;
   margin-top: 30px;
   margin-left: 15px;
   height: 600px;

  }
  .preview-container-bg{
    order: 1;
    margin-left: 15px;
  }
  .bride-groom-container{
    flex-direction: column;
  }

  .wed-detail-name{
    font-size: 26px;
  }
  .wed-detail-date {
    font-size: 20px;
}
.wed-detail-hastag{
  font-size: 18px;
}
.form-heading{   
  font-size: 24px;
  }

  .align-bottom{
    top:370px
  }
  .align-top{
    top:38px
  }
  .bride-groom-div{
    height: 450px;
    width: 90%;
  }
  .bride-groom-div >img{
    width: 90%;
    height: 75%;
  }
  .theme-heading{
    font-size: 26px;
  }
  .religious-element-container {
    margin-top: 25px;
  }
  
 .edit-reli-icon{
  align-self: flex-end;
  width: 35px !important;
  height: 35px !important;
 
 }

  
  .icon-display {
    width: 100px;
    height: 100px;
    margin: 15px 0;
  }

  
  
  .icon-option {
    width: 80px;
    height: 80px;
  }
  .religious-text-field{
    width: 55%;
    margin: 0 auto;
  }
  .blessing-input-feild{
    width: 85%;
    height: 160px;
  }
  .preview-custom-icon{
    width: 200px !important;
    height: 90px !important; 
}
.preview-banner-overlay{
  top:36px
}
.align-bottom{
  top:390px
}
}

@media (min-width:1400px){
  .wed-detail-name{
    font-size: 28px;
  }
  .wed-detail-date {
    font-size: 22px;
}
.wed-detail-hastag{
  font-size: 20px;
}
.bride-groom-div{
  height: 420px;
}
.bride-groom-div >h3{
  font-size: 20px;
}
.bride-groom-div >h4{
  font-size: 18px;
}
.bride-groom-div > img{
width: 80%;
height: 280px;
}
.form-heading{   
  font-size: 26px;
  }
}