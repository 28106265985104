.footer-slider{
    width: 95%;
    margin: 10px auto;
}

.footer-slider-image{
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: '0 4px 10px rgba(0, 0, 0, 0.3)';
}

@media(min-width:768px){
    .footer-slider{
        width: 70%;
    }
}

@media(min-width:1000px){
    .footer-slider{
        width: 55%;
    }
}