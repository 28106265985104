/* HTML: <div class="loader"></div> */
.center-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}
.loader {
    width: 90px;
    aspect-ratio: 1;
    display: grid;
  }
  .loader::before,
  .loader::after {    
    content:"";
    grid-area: 1/1;
    --c:no-repeat radial-gradient(farthest-side,#F04213, 92%,#d8f92000);
    background: 
      var(--c) 50%  0, 
      var(--c) 50%  100%, 
      var(--c) 100% 50%, 
      var(--c) 0    50%;
    background-size: 12px 12px;
    animation: l12 1s infinite;
  }
  .loader::before {
    margin: 4px;
    filter: hue-rotate(45deg);
    background-size: 8px 8px;
    animation-timing-function: linear
  }
  
  @keyframes l12 { 
    100%{transform: rotate(.5turn)}
  }